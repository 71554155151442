import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination]);
Swiper.use([Autoplay]);

let swiperReviews = new Swiper(".reviews__slider", {
  spaceBetween: 12,
  loop: false,
  grabCursor: false,
  slidesPerView: 2,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  navigation: {
    nextEl: ".reviews__next",
    prevEl: ".reviews__prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    1164: {
      slidesPerView: 1,
    },
    1165: {
      slidesPerView: 2,
      spaceBetween: 12,
    },
  },
});

let swiperVideo = new Swiper(".video__slider", {
  spaceBetween: 12,
  loop: false,
  grabCursor: false,
  slidesPerView: 4,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  navigation: {
    nextEl: ".video__next",
    prevEl: ".video__prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    968: {
      slidesPerView: 3,
    },
    1150: {
      slidesPerView: 4,
      spaceBetween: 12,
    },
  },
});
