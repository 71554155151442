$(".btn--phone").click(function () {
  $(".social__block--phone").css({
    opacity: "1",
    visibility: "visible",
  });
});

$(".social__close--phone").click(function () {
  $(".social__block--phone").css({
    opacity: "0",
    visibility: "hidden",
  });
});

$(".btn--youtube").click(function () {
  $(".social__block--youtube").css({
    opacity: "1",
    visibility: "visible",
  });
});

$(".social__close--youtube").click(function () {
  $(".social__block--youtube").css({
    opacity: "0",
    visibility: "hidden",
  });
});
